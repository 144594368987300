import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import feedbackReducer from '../slices/feedbackSlice';
import customersReducer from '../slices/customersSlice';
import itemsReducer from '../slices/itemsSlice';
import authReducer from '../slices/authSlice';
import organizationReducer from '../slices/organizationSlice';

// Every reducer must be included here to be accessible by the useSelector hook
const reducer = {
  auth: authReducer,
  items: itemsReducer,
  customers: customersReducer,
  feedback: feedbackReducer,
  organization: organizationReducer,
};

const store = configureStore({
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  reducer,
  devTools: true,
});

export default store;
