import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import SubscribeModal from './SubscribeModal/SubscribeModal';
import { displayAlert } from '../../../slices/feedbackSlice';

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '99%',
    padding: '20px',
  },
}));

export default function MyAccount() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const organization = useSelector((state) => state.organization.organization);
  const subscription = useSelector((state) => state.organization.subscription);
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);

  const location = useLocation().pathname;
  const paymentStatus = location.split('/').pop();

  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentStatus) {
      dispatch(displayAlert(t(`payment_status_${paymentStatus}`), paymentStatus));
    }
  }, [t, dispatch, paymentStatus]);

  const handleSubscribeModalClose = () => {
    setSubscribeModalOpen(false);
  };

  const handleSubscribeModalOpen = () => {
    setSubscribeModalOpen(true);
  };
  const subscriptionDisplay = (organization.is_subscribed || paymentStatus === 'success') ? (
    <>
      <Grid item xs={12}>
        <Typography component="p" variant="overline">
          {t(`plans.${subscription.interval}.name`)}
        </Typography>
      </Grid>
      {/* <Grid item xs={12}> */}
      {/*  <Button */}
      {/*    variant="contained" */}
      {/*    component="label" */}
      {/*    color="secondary" */}
      {/*    onClick={handleSubscribeModalOpen} */}
      {/*  > */}
      {/*    {t('manage_subscription')} */}
      {/*  </Button> */}
      {/* </Grid> */}
    </>
  )
    : (
      <>
        <Grid item xs={12}>
          <Typography component="p" variant="overline" color="error">
            {t('unsubscribed')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="body1">
            {t('please_subscribe')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            onClick={handleSubscribeModalOpen}
          >
            {t('subscribe')}
          </Button>
        </Grid>
      </>
    );

  return (
    <>
      <SubscribeModal display={subscribeModalOpen} handleClose={handleSubscribeModalClose} />
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography component="p" variant="body1">
            {t('account_description')}
          </Typography>
          <br />

        </Grid>

        {/* Subscription */}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              title={t('subscription')}
              titleTypographyProps={{ variant: 'h3' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                {subscriptionDisplay}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </>
  );
}
