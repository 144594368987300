import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { createCustomer } from '../../../../slices/customersSlice';
import { setLoading } from '../../../../slices/feedbackSlice';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}
));

export default function AddCustomerModal({ display, handleClose }) {
  const [customerProperties, setCustomerProperties] = useState({});
  const classes = useStyles();
  const { t } = useTranslation('customers');
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.feedback.loading);

  useEffect(() => {
    const customer = {};
    setCustomerProperties(
      {
        firstName: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          id: 'first_name',
          label: t('firstName'),
          name: 'firstName',
          value: customer.firstName || '',
        },
        lastName: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'lastName',
          label: t('lastName'),
          id: 'last_name',
          value: customer.lastName || '',
        },
        email: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'email',
          label: t('email'),
          id: 'email',
          value: customer.email || '',
        },
        language: {
          variant: 'outlined',
          required: true,
          select: true,
          fullWidth: true,
          name: 'language',
          label: t('language'),
          id: 'language',
          value: customer.status || 'fr',
          children:
                        [
                          <MenuItem key="fr" value="fr">
                            {t('add_customer.fr')}
                          </MenuItem>,
                          <MenuItem key="en" value="en">
                            {t('add_customer.en')}
                          </MenuItem>,
                        ]

          ,
        },
        phoneNumber: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          id: 'phone',
          value: customer.phoneNumber || '',
        },
        note: {
          name: 'note',
          variant: 'outlined',
          required: false,
          fullWidth: true,
          multiline: true,
          rows: 4,
          id: 'note',
          label: t('note'),
          value: customer.note || '',
        },
      },
    );
  }, [t]);

  const handleSubmit = () => {
    dispatch(setLoading(true));
    const newCustomer = Object.values(customerProperties).reduce((acc, elem) => {
      acc[elem.id] = elem.value;
      return acc;
    }, {});
    dispatch(createCustomer(
      newCustomer,
    )).unwrap()
      .then(() => {
        dispatch(setLoading(true));
        handleClose();
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    let { value, name } = target;
    if (name === 'isbn') {
      value = value.replace(/-/g, '');
    }
    if (name) {
      setCustomerProperties({
        ...customerProperties,
        [name]: {
          ...customerProperties[name],
          value,
        },
      });
    }
  };

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t('add_customer.title')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>

        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
          onChange={handleChange}
          onClick={handleChange}
        >
          <Grid container spacing={1}>

            {Object.values(customerProperties).map((property) => (
              <Grid item xs={12} key={property.id}>
                <TextField {...property} />
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"

            onClick={handleSubmit}
          >
            {t('add_customer.save_button')}
          </Button>
          {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>

      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
