import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DetailsBookModal from '../Screens/Books/DetailsBookModal/DetailsBookModal';
import HelmetMetaData from '../HelmetMetaData/HelmetMetaData';
import { setLoading } from '../../slices/feedbackSlice';
import Collection from '../Collection/Collection';
import Search from '../Search/Search';
import { spinner } from '../Layout/Spinner/Spinner';
import { getSharedCategories, getSharedCollection } from '../../slices/itemsSlice';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  button: {
    width: '281.25px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function Shared() {
  const classes = useStyles();
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const [detailsBookModalOpen, setDetailsBookModalOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    query: '',
    available: false,
    categoryIds: [],
  });
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [t] = useTranslation('myBooks');

  const collectionSlug = location.split('/').pop();

  const loading = useSelector((state) => state.feedback.loading);

  const categories = useSelector((state) => state.items.categories);
  const books = useSelector((state) => state.items.sharedCollection.books);
  const collection = useSelector((state) => state.items.sharedCollection || []);

  useEffect(() => {
    dispatch(getSharedCategories({ slug: collectionSlug }));
  }, [dispatch, collectionSlug]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getSharedCollection({ slug: collectionSlug, filters }))
      .unwrap()
      .then(() => dispatch(setLoading(false)))
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  }, [filters, dispatch, collectionSlug]);

  useEffect(() => {
    if (!books.results.length) {
      setEmptyListFlag(true);
    } else {
      setEmptyListFlag(false);
    }
  }, [books]);

  const handleOpenBookModal = (event, book) => {
    setSelectedBook(book);
    setDetailsBookModalOpen(true);
  };

  const handleCloseBookModal = () => {
    setDetailsBookModalOpen(false);
    setSelectedBook({});
  };

  const handleSearch = (query) => {
    const newFilters = { ...filters, query, page: 1 };
    setFilters(newFilters);
  };

  const handleOnFilterCategories = (event, categoriesQuery) => {
    const categoryIds = categoriesQuery.map((category) => category.id);
    const newFilters = { ...filters, categoryIds, page: 1 };
    setFilters(newFilters);
  };

  const handleAvailableFilter = (checked) => {
    const newFilters = { ...filters, available: checked, page: 1 };
    setFilters(newFilters);
  };

  const handleSetNewPageNumber = (number) => {
    const newFilters = { ...filters, page: number };
    setFilters(newFilters);
  };

  return (
    <>
      {detailsBookModalOpen && (
        <DetailsBookModal
          display={detailsBookModalOpen}
          handleClose={handleCloseBookModal}
          book={selectedBook}
          categories={categories}
          collection={collection}
        />
      )}
      <HelmetMetaData
        quote={t('sharing.quote')}
        title={t('sharing.title')}
        image="https://labibli.com/assets/images/image04.jpg?v=c75d1198"
        description={t('sharing.description')}
        hashtag={t('sharing.hashtag')}
      />
      <Container component="main" className={classes.paper}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.content}
          >
            <Search
              onSearch={handleSearch}
              setOnlyAvailableFilter={handleAvailableFilter}
              handleOnFilterCategories={handleOnFilterCategories}
              categories={categories}
            />
          </Grid>
          <Grid item xs={12}>
            {(emptyListFlag && !loading)
                            && (
                            <Typography type="p" variant="body1">
                              <span role="img" aria-label="red cross">❌</span>
                              {' '}
                              {t('search.no_book_found')}
                            </Typography>
                            )}
          </Grid>
          {loading
            ? spinner
            : (
              <Collection
                books={books}
                onBookClick={handleOpenBookModal}
                collection={collection}
                handleSetNewPageNumber={handleSetNewPageNumber}
                page={filters.page}
              />
            )}
        </Grid>
      </Container>

    </>
  );
}
