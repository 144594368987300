import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';
import { signup, setHasAccount, login } from '../../../slices/authSlice';
import { displayAlert } from '../../../slices/feedbackSlice';

const useStyles = makeStyles((theme) => ({

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: 'center',
    color: theme.palette.error.main,
    textTransform: 'title',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Signup(props) {
  const { t } = useTranslation('signin');

  const [fields, setFields] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasAccount = useSelector((state) => state.auth.hasAccount);
  const loading = useSelector((state) => state.feedback.loading);
  const [error, setError] = useState('');
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    setFields(
      {
        email: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          id: 'email',
          label: t('email_address'),
          name: 'email',
          autoComplete: 'email',
          value: '',
        },
        password: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'password',
          label: t('password'),
          type: 'password',
          id: 'password',
          autoComplete: 'current-password',
          value: '',
        },
        firstName: {
          autoComplete: 'firstName',
          name: 'firstName',
          variant: 'outlined',
          required: true,
          fullWidth: true,
          id: 'firstName',
          label: t('firstName'),
          value: '',
        },
        lastName: {
          autoComplete: 'lastName',
          name: 'lastName',
          variant: 'outlined',
          required: true,
          fullWidth: true,
          id: 'lastName',
          label: t('lastName'),
          value: '',
        },
      },
    );
  }, [t]);

  if (hasAccount) {
    return <Redirect to="/login" />;
  }

  const handleSignup = (firstName, lastName, email, password) => {
    setError('');
    console.log('signup', firstName, lastName);

    dispatch(signup({
      firstName, lastName, email, password,
    }))
      .unwrap()
      .then(() => {
        setError('');
        dispatch(displayAlert(t('account_created'), 'success'));
        dispatch(login({ password, email }));
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    handleSignup(
      fields.lastName.value,
      fields.firstName.value,
      fields.email.value,
      fields.password.value,
    );
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {hasAccount ? t('signin') : t('signup')}
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField {...fields.firstName} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.lastName} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.email} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.password} />
            </Grid>
          </Grid>
          {error && (
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.errorMessage}>
                {error}
              </Grid>
            </Grid>
          )}
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {t('signup')}
            </Button>
            {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <Grid container>

            <Grid item>
              <Link
                href="# "
                onClick={() => dispatch(setHasAccount(true))}
                variant="body2"
              >
                { t('already_have_an_account')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
