import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createCategory } from '../../../../slices/itemsSlice';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}
));

export default function AddCategoryModal({ display, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [categoryProperties, setCategoryProperties] = useState({});
  const classes = useStyles();
  const { t } = useTranslation('categories');
  const dispatch = useDispatch();

  useEffect(() => {
    const category = {};
    setCategoryProperties(
      {
        name: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          id: 'name',
          label: t('name'),
          name: 'name',
          value: category.name || '',
        },
      },
    );
  }, [t]);

  const handleSubmit = () => {
    setLoading(true);
    const newCategory = Object.values(categoryProperties)
      .reduce((acc, elem) => {
        acc[elem.id] = elem.value;
        return acc;
      }, {});
    dispatch(createCategory(
      newCategory,
    ));
    setLoading(false);
    handleClose();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { value, name } = target;
    if (name) {
      setCategoryProperties({
        ...categoryProperties,
        [name]: {
          ...categoryProperties[name],
          value,
        },
      });
    }
  };

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t('add_category.title')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
          onChange={handleChange}
          // onClick={handleChange}
        >
          <Grid container spacing={1}>

            {Object.values(categoryProperties)
              .map((property) => (
                <Grid item xs={12} key={property.id}>
                  <TextField {...property} />
                </Grid>
              ))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {t('add_category.save_button')}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>

      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
