import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  validateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function LendingDetailsModal({
  display, handleClose, lending, handleReturnModalOpen,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['lendings']);

  const handleReturn = () => {
    handleReturnModalOpen();
    handleClose();
  };

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t('details_modal.title')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" />
          </Grid>
          {lending.bookTitle
            && (
            <>
              <Grid item xs={12} md={6}>
                <Typography type="h3" variant="h3">
                  {t('details_modal.book')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.bookTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('lent_on')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.startDate.toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('due_on')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.dueOn.toLocaleDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            )}

          {lending.customer
            && (
            <>
              <Grid item xs={12} md={6}>
                <Typography type="h3" variant="h3">
                  {t('details_modal.customer')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('name')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {`${lending.customer.first_name} ${lending.customer.last_name}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('email')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.customer.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('phoneNumber')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.customer.phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('language')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.customer.language}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="h5">
                      {t('note')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography type="h4" variant="body1">
                      {lending.customer.note}
                    </Typography>
                  </Grid>

                </Grid>

              </Grid>
            </>
            ) }

        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container alignItems="flex-end" justifyContent="flex-end" direction="row">
          <Grid item xs={6} className={classes.validateButton}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              onClick={handleReturn}
            >
              {t('details_modal.return_button')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
