import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import AddCategoryModal from './AddCategoryModal/AddCategoryModal';
import { deleteCategory, updateCategory } from '../../../slices/itemsSlice';

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '99%',
    padding: '20px',
  },
}));

export default function Categories() {
  const classes = useStyles();
  const { t } = useTranslation('categories');
  const categories = useSelector((state) => state.items.categories)
    .map((category) => ({ ...category }));

  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleAddCategoryModalClose = () => {
    setAddCategoryModalOpen(false);
  };

  const handleAddCategoryModalOpen = () => {
    setAddCategoryModalOpen(true);
  };

  return (
    <>
      <AddCategoryModal
        display={addCategoryModalOpen}
        handleClose={handleAddCategoryModalClose}
      />
      {/* <ViewCustomerModal
      display={viewCustomerModalOpen}
      handleClose={handleViewCustomerModalClose} /> */}
      {/* <EditCustomerModal
      display={editCustomerModalOpen}
      handleClose={handleEditCustomerModalClose} /> */}
      {/* <DeleteCustomerModal
       display={deleteCustomerModalOpen}
       handleClose={handleDeleteCustomerModalClose} /> */}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h2">
            {t('categories')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            onClick={handleAddCategoryModalOpen}
          >
            {t('add_category.button')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              { title: t('name'), field: 'name' },
            ]}
            data={categories}
            title={t('categories')}
            editable={{
              onRowUpdate: (newData, oldData) => dispatch(updateCategory(newData))
                .unwrap(),
              onRowDelete: (newData, oldData) => new Promise((resolve, reject) => {
                console.log('new', newData);
                dispatch(deleteCategory(newData))
                  .unwrap()
                  .then(() => resolve());
              }),
            }}
            localization={{
              toolbar: {
                searchTooltip: t('table.search'),
                searchPlaceholder: t('table.search'),
              },
              pagination: {
                labelDisplayedRows: t('table.row_count'),
                labelRowsSelect: t('table.rows'),
              },
              body: {
                editRow:
                    {
                      deleteText: t('table.delete_confirmation'),
                    },
              },
            }}
            options={{
              emptyRowsWhenPaging: false,
              pageSize: 20,
            }}
          />

        </Grid>

      </Grid>
    </>
  );
}
