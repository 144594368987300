import Login from '../components/Screens/Login/Login';
import Signup from '../components/Screens/Signup/Signup';
import ResetPassword from '../components/Screens/ResetPassword/ResetPassword';
import ResetPasswordVerify from '../components/Screens/ResetPassword/ResetPasswordVerify';
import Customers from '../components/Screens/Customers/Customers';
import Categories from '../components/Screens/Categories/Categories';
import Lendings from '../components/Screens/Lendings/Lendings';
import MyAccount from '../components/Screens/MyAccount/MyAccount';
import Books from '../components/Screens/Books/Books';
import Shared from '../components/Shared/Shared';

// Add routes here.
// Authentication routes should not be modified.
// Public routes are always rendered and visible in the navbar.
// Private routes are rendered and visible in the navbar only for authenticated users.
// Note: even though private components are not rendered for unauthorized users,
// keep in mind that it's all JavaScript.The code is running on the client side,
// and by consequent accessible.

const routes = {
  default: {
    path: '/books',
  },
  authentication: {
    login: {
      name: 'login',
      path: '/login',
      component: Login,
      minimalLayout: true,
      inNav: false,
    },
    signup: {
      name: 'signup',
      path: '/signup',
      component: Signup,
      minimalLayout: true,
      inNav: false,
    },

  },
  protected: [
    {
      name: 'myAccount',
      path: '/account',
      component: MyAccount,
      minimalLayout: false,
      inNav: true,
    },
    {
      name: 'books',
      path: '/books',
      component: Books,
      minimalLayout: false,
      inNav: true,
    },
    {
      name: 'customers',
      path: '/customers',
      component: Customers,
      minimalLayout: false,
      inNav: true,
    },
    {
      name: 'lendings',
      path: '/lendings',
      component: Lendings,
      minimalLayout: false,
      inNav: true,
    },
    {
      name: 'categories',
      path: '/categories',
      component: Categories,
      minimalLayout: false,
      inNav: true,
    },

  ],
  public: [
    {
      name: 'collection',
      path: '/collection',
      component: Shared,
      minimalLayout: true,
      inNav: false,
    },
    {
      name: 'passwordReset',
      path: '/password/reset',
      component: ResetPassword,
      minimalLayout: true,
      inNav: false,
    },
    {
      name: 'passwordResetVerify',
      path: '/password/verify',
      component: ResetPasswordVerify,
      minimalLayout: true,
      inNav: false,
    },
  ],
  admin: [],
};

export default routes;
