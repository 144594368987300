import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

export default function Search({ onSearch, setOnlyAvailableFilter,handleOnFilterCategories, categories }) {
  const [typingTimeout, setTypingTimeout] = useState(0);

  const { t } = useTranslation('search');

  const handleSearchInput = (event) => {
    const { value } = event.target;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      onSearch(value);
    }, 500));
  };

  const handleAvailableSwitch = (event) => {
    const { checked } = event.target;
    setOnlyAvailableFilter(checked);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          variant="outlined"
          fullWidth
          onChange={handleSearchInput}
          startAdornment={(
            <InputAdornment position="start">
              <SearchSharpIcon />
            </InputAdornment>
            )}
        />
      </Grid>
      {!!categories &&
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={categories}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={handleOnFilterCategories}

          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('category_placeholder')}
              label={t('categories')}
            />
          )}
        />
      </Grid>}
      { setOnlyAvailableFilter &&
      <Grid item xs={12}>
        <FormControlLabel
          control={(
            <Switch
              onChange={handleAvailableSwitch}
            />
        )}
          label={t('availableSwitch')}
        />
      </Grid>}
    </Grid>
  );
}

Search.propTypes = {
  onSearch: PropTypes.func,
  setOnlyAvailableFilter: PropTypes.func,
};

Search.defaultProps = {
  onSearch: () => {},
  setOnlyAvailableFilter: null,
};
