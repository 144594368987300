import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  resetPasswordVerified, resetPasswordVerify,
} from '../../../slices/authSlice';
import { displayAlert, setLoading } from '../../../slices/feedbackSlice';

const useStyles = makeStyles((theme) => ({

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: 'center',
    color: theme.palette.error.main,
    textTransform: 'title',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ResetPasswordVerify(props) {
  const { t } = useTranslation('signin');

  const [fields, setFields] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.feedback.loading);
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    setFields(
      {
        password: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'password',
          label: t('password'),
          type: 'password',
          id: 'password',
          autoComplete: 'current-password',
          value: '',
        },
      },
    );
  }, [t]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(resetPasswordVerify({ code }))
      .unwrap()
      .then(() => {
        setVerified(true);
        dispatch(setLoading(false));
      })
      .catch(() => {
        setVerified(false);
        setError(t('code_invalid'));
        dispatch(displayAlert(t('code_invalid'), 'error'));
        dispatch(setLoading(false));
      });
  }, [dispatch, t, code]);

  const handleReset = (newPassword) => {
    dispatch(setLoading(true));
    setError('');
    dispatch(resetPasswordVerified({ code, newPassword }))
      .unwrap()
      .then(() => {
        setError('');
        dispatch(setLoading(false));
        dispatch(displayAlert(t('reset_success'), 'success'));
        window.location.replace('/login');
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setError(err.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleReset(
      fields.password.value,
    );
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('reset_title')}
        </Typography>
        {verified
          ? (
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField {...fields.password} />
                </Grid>
              </Grid>
              {error && (
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.errorMessage}>
                  {error}
                </Grid>
              </Grid>
              )}
              <div className={classes.wrapper}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  { t('reset') }
                </Button>
                {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </div>
            </form>
          )
          : (
            <Typography component="p" variant="body1">
              {error || t('code_verifying')}
            </Typography>
          )}

      </div>
    </Container>
  );
}
