import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function CustomPagination({ handleSetNewPageNumber, count, page }) {
  const classes = useStyles();
  const handleChange = (event, value) => {
    handleSetNewPageNumber(value);
  };
  return (
    <div className={classes.pagination}>
      <Pagination count={count} page={page} size="large" color="secondary" onChange={handleChange} />
    </div>
  );
}
