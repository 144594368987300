import React from 'react';

import MinimalLayout from './Minimal/MinimalLayout';
import MainLayout from './Main/MainLayout';
import Snackbar from './Feedback/Snackbar/Snackbar';

const Layout = (props) => {
  const layout = props.minimal
    ? (
      <MinimalLayout {...props}>
        {' '}
        {props.children}
      </MinimalLayout>
    )
    : (<MainLayout {...props}>{props.children}</MainLayout>);

  return (
    <>
      {layout}
      <Snackbar />
    </>
  );
};

export default Layout;
