import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';

// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import dotenv from 'dotenv';
import * as serviceWorker from './serviceWorker';
import store from './app/store';
import App from './App';
import { sentryUrl } from './config/config';
import { spinner } from './components/Layout/Spinner/Spinner';
import './i18n';

dotenv.config();

// const stripePromise = loadStripe(stripe.publicApiKEy);

const environment = process.env.NODE_ENV ? process.env.NODE_ENV : 'production';

if (environment !== 'development') {
  Sentry.init({
    dsn: sentryUrl,
    environment,

  });
}

// store.dispatch(verifyAuth(firebase));

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={spinner}>
        {/* <Elements stripe={stripePromise}> */}
        <App />
        {/* </Elements> */}
      </Suspense>
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
