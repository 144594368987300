import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const LOGIN_URL = `${API_URL}/users/login/`;
const SIGNUP_URL = `${API_URL}/users/signup/`;

const signup = (firstName, lastName, email, password) => axios.post(SIGNUP_URL, {
  first_name: firstName,
  last_name: lastName,
  email,
  password,
});

const login = (email, password) => axios
  .post(LOGIN_URL, {
    email,
    password,
  })
  .then((response) => {
    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });

const logout = () => {
  localStorage.removeItem('user');
};

const resetPassword = (email) => axios.post(`${API_URL}/users/password/reset/`, {
  email,
});

const resetPasswordVerify = (code) => axios.get(`${API_URL}/users/password/reset/verify/?code=${code}`);

const resetPasswordVerified = (code, newPassword) => axios.post(`${API_URL}/users/password/reset/verified/`,
  {
    code,
    password: newPassword,
  });

const authService = {
  signup,
  login,
  logout,
  resetPassword,
  resetPasswordVerify,
  resetPasswordVerified,
};

export default authService;
