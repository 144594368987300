import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';

import { displayAlert } from '../../../../slices/feedbackSlice';
import { deleteBook } from '../../../../slices/itemsSlice';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  deleteButton: {
    color: 'darkred',
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

export default function DeleteBookModal({
  display,
  handleClose,
  book,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['myBooks', 'customers']);


  const handleDelete = () => {
    dispatch(deleteBook(book))
      .unwrap()
      .then(
        () => {
          dispatch(displayAlert(t('delete_modal.success'), 'success'));
          handleClose();
        },
      )
      .catch(
        () => { dispatch(displayAlert(t('delete_modal.error'), 'error')); },
      );
  };

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t('delete_modal.title')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>

        <Typography type="p" variant="body1">
          {t('delete_modal.delete_book_text')}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        {/* <Grid container alignItems="flex-end" justify="flex-end" direction="row"> */}
        {/*  <Grid item xs={6} className={classes.cancelButton}> */}
        <Button
          variant="contained"
          component="label"
          color="secondary"
          onClick={handleClose}
        >
          {t('delete_modal.cancel_button')}
        </Button>
        {/* </Grid> */}
        {/* <Grid item xs={6} className={classes.deleteButton}> */}
        <Button
          variant="contained"
          component="label"
          color="primary"
          onClick={handleDelete}
        >
          {t('delete_modal.delete_button')}
        </Button>
        {/*  </Grid> */}
        {/* </Grid> */}
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
