import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Layout from './components/Layout/Layout';
import './App.css';
import routes from './config/routes';
import theme from './theme';

function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const withLayout = (Component, minimalLayout) => () => (
    <Layout minimal={minimalLayout}><Component /></Layout>
  );

  const defineRoute = (route) => {
    const Component = withLayout(route.component, route.minimalLayout);
    return (
      <Route
        path={route.path}
        component={Component}
        key={route.path}
      />
    );
  };

  const publicRoutes = (routes.public.map((route) => defineRoute(route)));

  const loginRoute = defineRoute(routes.authentication.login);
  const signupRoute = defineRoute(routes.authentication.signup);

  const protectedRoutes = (routes.protected.map((route) => defineRoute(route)));

  const defaultRedirect = isLoggedIn ? routes.default.path : routes.authentication.login.path;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        ...
        {publicRoutes}
        ...
        {isLoggedIn ? protectedRoutes : []}
        {loginRoute}
        {signupRoute}
        <Redirect to={defaultRedirect} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
