import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon from '../../assets/images/logo.svg';
import defaultImage from '../../assets/images/logo.svg';

export default function HelmetMetaData({
  quote,
  title,
  image,
  description,
  hashtag,
}) {
  const { t, i18n } = useTranslation('header');

  const location = useLocation();
  const { language } = i18n;
  const currentUrl = `https://app.labibli.com${location.pathname}`;
  image = image || defaultImage;

  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={icon} />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title || t('title')} />
      <meta property="quote" content={quote || t('quote')} />
      <meta name="description" content={description || t('description')} />
      <meta property="image" content={image} />
      <meta property="og:locale" content={language} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || t('title')} />
      <meta property="og:quote" content={quote || t('quote')} />
      <meta property="og:hashtag" content={hashtag || t('hashtag')} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="La Bibli" />
      <meta property="og:description" content={description || t('description')} />
    </Helmet>
  );
}
