import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import { useTranslation } from 'react-i18next';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
  },
}));

export default function Plan({ plan, selected, onClick }) {
  const [t] = useTranslation('myAccount');

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} key={plan.id}>
      <Card raised={selected} className={classes.card}>
        <CardActionArea onClick={() => onClick(plan.id)}>
          <CardHeader
            title={t(`plans.${plan.name}.name`)}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p">
                  {t(`plans.${plan.name}.description`)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" component="p">
                  {t(`plans.${plan.name}.price`)}
                </Typography>
              </Grid>
            </Grid>

          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
