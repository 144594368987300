export const app = {
  name: 'La Bibli',
};

export const firebase = {
  apiKey: 'AIzaSyBlGRukL_UIhnHw7V75n8ClUYD6eSKOrVA',
  authDomain: 'biblio-44466.firebaseapp.com',
  databaseURL: 'https://biblio-44466.firebaseio.com',
  projectId: 'biblio-44466',
  storageBucket: 'biblio-44466.appspot.com',
  messagingSenderId: '771439924140',
  appId: '1:771439924140:web:47e98940edcd474f27a009',

};

export const reduxFirebase = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false,
};

export const sentryUrl = 'https://d883dde5b84845a3a4b06d868acb155d@o413315.ingest.sentry.io/5743582';

export const LANGUAGES_LABELS = [
  { code: 'fr', text: 'Francais' },
  { code: 'en', text: 'English' },
];

export const stripe = {
  publicApiKEy: 'pk_live_51H39kMCpTgAka9PhYMIpM13f8rigwif8TVAf7FOQqGwagZV66rtGAHYo64jeP92on6DbAY4cUDyRGxMmeCdTlutr00l09aKoSJ',
};
