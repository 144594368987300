export const hashString = (string) => btoa(unescape(encodeURIComponent(string)));

export const manageError = (error) => {
  if (error.responde && error.response.status === 400) {
    const { data } = error.response;
    const message = Object.entries(data).map(([key, value]) => `${key} : ${value[0]}`);
    return message.join(', ');
  }
  console.error(error)
  return 'Une erreur inconnue est survenue.';
};

export const displayDate = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleDateString();
};

export const replaceObjectWithId = (array, newObject) => array.map((oldObject) => {
  if (oldObject.id === newObject.id) {
    return newObject;
  }
  return oldObject;
});

export const removeObjectWithId = (array, newObject) => array.filter(
  (oldObject) => oldObject.id !== newObject.id,
);
