import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomersService from '../services/customers.service';
import { displayAlert } from './feedbackSlice';
import { manageError } from '../utils/helpers';

export const getCustomers = createAsyncThunk(
  'customers/list',
  async (thunkAPI) => {
    try {
      const data = await CustomersService.getCustomers();
      return { customers: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createCustomer = createAsyncThunk(
  'customers/post',
  async (customerData, thunkAPI) => {
    try {
      const data = await CustomersService.createCustomer(customerData);
      return { customer: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
  },
  reducers: {},
  extraReducers: {
    [getCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload.customers;
    },
    [getCustomers.rejected]: (state, action) => {
      state.customers = [];
    },
    [createCustomer.fulfilled]: (state, action) => {
      state.customers = [...state.customers, action.payload.customer];
    },
  },
});

export default customersSlice.reducer;
