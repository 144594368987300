import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import { NavLink } from 'react-router-dom';
import { displayAlert } from '../../../../slices/feedbackSlice';
import SocialMediaButtons from '../../../Layout/SharingButtons/SharingButtons';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  sharingButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  copyButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ShareModal({ display, handleClose, sharingLink }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('myBooks');

  const isSubscribed = useSelector((state) => state.organization.organization.is_subscribed);

  const handleCopy = () => {
    navigator.clipboard.writeText(sharingLink);
    dispatch(displayAlert(t('share_modal.link_copied'), 'success'));
  };

  const modalContent = (
    isSubscribed
      ? (
    // Subscribed
        <>
          <DialogTitle className={classes.root} disableTypography>
            <Typography type="h3" variant="h3">
              {t('share_modal.title')}
            </Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>

          <DialogActions>
            <Grid container alignItems="flex-end" justify="flex-end" direction="row">
              <Grid item xs={6} className={classes.sharingButton}>
                <SocialMediaButtons
                  url={sharingLink}
                  quote={t('sharing.quote')}
                  hashtags={t('sharing.hashtags').split()}
                />
              </Grid>
              <Grid item xs={6} className={classes.copyButton}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={handleCopy}
                >
                  {t('share_modal.copy_link')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )
      : (

    // Not subscribed
        <>
          <DialogTitle className={classes.root} disableTypography>
            <Typography type="h3" variant="h3">
              {t('share_modal.title')}
            </Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <DialogContent dividers>
            <Typography type="p" variant="body1">
              {t('share_modal.no_subscription')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Grid container alignItems="flex-end" justify="flex-end" direction="row">
              <Grid item xs={6} className={classes.copyButton}>
                <Button
                  variant="contained"
                  color="primary"
                  component={NavLink}
                  to="myaccount"
                >
                  {t('share_modal.subscribe')}
                </Button>
              </Grid>
            </Grid>

          </DialogActions>
        </>
      )
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
