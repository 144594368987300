import React, { useState } from 'react';
// import { useStripe } from '@stripe/react-stripe-js';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import PaymentService from '../../../../services/payment.service';

import Plans from './Plans/Plans';
import { displayAlert } from '../../../../slices/feedbackSlice';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

export default function SubscribeModal({ display, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedPlanId, setSelectedPlanId] = useState('price_1JlIjMCpTgAka9PhQMYIDJLJ');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('myAccount');

  const handleSelectPlan = (planId) => {
    setSelectedPlanId(planId);
  };

  const handleStripeCheckout = () => {
    setLoading(true);
    const priceId = selectedPlanId;
    PaymentService.createCheckoutLink({ priceId })
      .then((result) => window.location.href = result.data.url)
      .catch((err) => dispatch(displayAlert(t('checkout_error'), 'error')));
  };

  return (

    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle disableTypography>
        <Typography type="h4" variant="h4">
          {t('subscribe_modal.title')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Plans
              selectedPlanId={selectedPlanId}
              handleSelectPlan={handleSelectPlan}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography type="p" variant="body1">
              *
              {t('subscribe_modal.reduction')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>

        <div className={classes.wrapper}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            onClick={handleStripeCheckout}
          >
            {t('subscribe_modal.pay_button')}
          </Button>
          {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>

    </Dialog>
  );
}
