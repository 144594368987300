import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrganizationService from '../services/organization.service';
import { displayAlert } from './feedbackSlice';
import { manageError } from '../utils/helpers';

export const getOrganization = createAsyncThunk(
  'organization/get',
  async (thunkAPI) => {
    try {
      const data = await OrganizationService.getOrganization();
      return { organization: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createOrganization = createAsyncThunk(
  'organization/post',
  async (organizationData, thunkAPI) => {
    try {
      const data = await OrganizationService.createOrganization(organizationData);
      return { organization: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getSubscription = createAsyncThunk(
  'subscription/get',
  async (thunkAPI) => {
    try {
      const data = await OrganizationService.getSubscription();
      return { subscription: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organization: {},
    subscription: { plan: null, interval: null, active: false },
  },
  reducers: {},
  extraReducers: {
    [getOrganization.fulfilled]: (state, action) => {
      state.organization = action.payload.organization;
    },
    [getOrganization.rejected]: (state, action) => {
      state.organization = {};
    },
    [createOrganization.fulfilled]: (state, action) => {
      state.organization = action.payload.organization;
    },
    [getSubscription.fulfilled]: (state, action) => {
      state.subscription = action.payload.subscription;
    },
    [getSubscription.rejected]: (state, action) => {
      state.subscription = {};
    },
  },
});

export default organizationSlice.reducer;
