import React from 'react';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NavigationItem from './NavigationItem/NavigationItem';
import routes from '../../../config/routes';

const NavigationItems = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const protectedRoutes = isLoggedIn ? routes.protected : [];
  const allRoutes = [...protectedRoutes, ...routes.public];
  const { t } = useTranslation('common');

  return (
    <List>
      {allRoutes.map((route) => route.inNav && (
        <NavigationItem
          key={route.path}
          link={route.path}
        >
          {t(`navbar.${route.name}`)}
        </NavigationItem>
      ))}
    </List>

  );
};

export default NavigationItems;
