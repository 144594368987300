import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

// Categories
const getCategories = () => axios.get(`${API_URL}/items/categories/`, { headers: authHeader() });
const createCategory = (data) => axios.post(
  `${API_URL}/items/categories/`,
  data,
  { headers: authHeader() },
);

const updateCategory = (data) => axios.put(
  `${API_URL}/items/categories/${data.id}/`,
  data,
  { headers: authHeader() },
);

const deleteCategory = (categoryId) => axios.delete(
  `${API_URL}/items/categories/${categoryId}/`,
  { headers: authHeader() },
);

// Lendings
const getLendings = () => axios.get(`${API_URL}/items/lendings/`, { headers: authHeader() });
const createLending = (data) => axios.post(
  `${API_URL}/items/lendings/`,
  data,
  { headers: authHeader() },
);

const returnLending = (lendingId) => axios.post(
  `${API_URL}/items/lendings/${lendingId}/return/`,
  {},
  { headers: authHeader() },
);

// Books
const getBooks = (filters = {
  categoryIds: [],
  page: 1,
  query: '',
  available: false,
}) => {
  const categories = filters.categoryIds.map((id) => `&categoryId=${id}`).join('');
  return axios.get(`${API_URL}/items/books/?query=${filters.query}&available=${filters.available}&page=${filters.page}${categories}`,
    { headers: authHeader() });
};

const createBook = (bookData) => {
  const formData = new FormData();
  Object.keys(bookData).forEach((key) => {
    if (bookData[key] !== null) {
      formData.append(key, bookData[key]);
    }
  });

  if (bookData.picture) {
    formData.set('picture', bookData.picture, 'blob.png');
  } else {
    formData.delete('picture');
  }

  if (bookData.categories) {
    for (let i = 0; i < bookData.categories.length; i++) {
      formData.append(`categories[${i}]id`, bookData.categories[i].id);
      formData.append(`categories[${i}]name`, bookData.categories[i].name);
    }
  }

  return axios.post(
    `${API_URL}/items/books/`,
    formData,
    { headers: authHeader() },
  );
};

const updateBook = (bookId, bookData) => {
  const formData = new FormData();
  Object.keys(bookData).forEach((key) => {
    if (bookData[key] !== null) {
      formData.append(key, bookData[key]);
    }
  });

  if (bookData.picture) {
    formData.set('picture', bookData.picture, 'blob.png');
  } else {
    formData.delete('picture');
  }

  if (bookData.categories) {
    for (let i = 0; i < bookData.categories.length; i++) {
      formData.append(`categories[${i}]id`, bookData.categories[i].id);
      formData.append(`categories[${i}]name`, bookData.categories[i].name);
    }
  }
  return axios.put(
    `${API_URL}/items/books/${bookId}/`,
    formData,
    { headers: authHeader() },
  );
};

const deleteBook = (bookId) => axios.delete(
  `${API_URL}/items/books/${bookId}/`,
  { headers: authHeader() },
);

const bookLookup = (isbn) => axios.get(
  `${API_URL}/items/books/lookup/`,
  {
    params: { isbn },
    headers: authHeader(),
  },
);

const downloadImage = (url) => axios.get(
  `${API_URL}/items/books/image/`,
  {
    params: { image_url: url },
    headers: authHeader(),
    responseType: 'blob',
  },
);

// Collections
const getSharedCollection = (
  slug,
  filters = {
    categoryIds: [],
    page: 1,
    query: '',
    available: false,
  },
) => {
  const categories = filters.categoryIds.map((id) => `&categoryId=${id}`).join('');
  return axios.get(`${API_URL}/items/collections/${slug}/?query=${filters.query}&available=${filters.available}&page=${filters.page}${categories}`);
};
const getSharedCategories = (slug) => axios.get(`${API_URL}/items/categories/collection/${slug}/`);

const getCollections = (slug) => axios.get(`${API_URL}/items/collections/`, { headers: authHeader() });

const itemsService = {
  getCategories,
  createCategory,
  updateCategory,
  getLendings,
  createLending,
  returnLending,
  getBooks,
  createBook,
  updateBook,
  bookLookup,
  downloadImage,
  getSharedCollection,
  getCollections,
  getSharedCategories,
  deleteCategory,
  deleteBook,
};

export default itemsService;
