const white = '#FFFFFF';
const black = '#000000';

const text = {
  primary: '#0a2925',
  secondary: '#2e3201',
  error: '#ff0000',
};

const green = {
  main: '#43a396',
  // contrastText: text.primary
};

const neon = {
  main: '#d2e603',
};


const palette = {
  text,
  primary: green,
  secondary: neon,
  white,
  black,
  background: {
    default: '#f8fcfb',
  },
};

export default palette;
