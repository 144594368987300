import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import ShareModal from './ShareModal/ShareModal';
import AddBookModal from './AddBookModal/AddBookModal';
import Collection from '../../Collection/Collection';
import Search from '../../Search/Search';

import DetailsBookModal from './DetailsBookModal/DetailsBookModal';
import LendBookModal from './LendBookModal/LendBookModal';
import { getBooks, getCollections } from '../../../slices/itemsSlice';
import { setLoading } from '../../../slices/feedbackSlice';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  image: {
    height: '450px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  cardContent: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
    height: '100%',
    alignContent: 'bottom',
  },
  card: {
    height: '100%',
    width: '281.25px',
  },
  bookDetails: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '281.25px',
  },
}));


export default function Books() {
  const classes = useStyles();
  const [addBookModalOpen, setAddBookModalOpen] = useState(false);
  const [detailsBookModalOpen, setDetailsBookModalOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState({});
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    query: "",
    available: false,
    categoryIds : []
  });
  const [lendBookModalOpen, setLendBookModalOpen] = useState(false);
  const [bookToLend, setBookToLend] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation('myBooks');
  const collections = useSelector((state) => state.items.collections);
  const categories = useSelector((state) => state.items.categories);
  const books = useSelector((state) => state.items.books);

  useEffect(() => {
    dispatch(getCollections());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getBooks())
      .unwrap()
      .then(() => dispatch(setLoading(false)))
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getBooks(filters))
        .unwrap()
        .then(() => dispatch(setLoading(false)))
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
        });
  }, [filters, dispatch]);

  const handleAddBookModalClose = () => {
    setAddBookModalOpen(false);
  };

  const handleAddBookModalOpen = () => {
    setSelectedBook({});
    setAddBookModalOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  const handleShareModalOpen = () => {
    setShareModalOpen(true);
  };

  const handleBookOpen = (event, book) => {
    setSelectedBook(book);
    setDetailsBookModalOpen(true);
  };

  const handleBookEdit = (event, book) => {
    setSelectedBook(book);
    setAddBookModalOpen(true);
    setDetailsBookModalOpen(false);
  };

  const handleSearch = (query) => {
    const newFilters = {...filters, query, page:1}
    setFilters(newFilters)
  };

  const handleOnFilterCategories = (event, categoriesQuery) => {
    const categoryIds = categoriesQuery.map(category => category.id)
    const newFilters = {...filters, categoryIds, page:1}
    setFilters(newFilters)
  };

  const handleAvailableFilter = (checked) => {
    const newFilters = {...filters, available: checked, page:1}
    setFilters(newFilters)
  };

  const handleCloseBookModal = () => {
    setDetailsBookModalOpen(false);
    setSelectedBook({});
  };

  const handleBookLend = (event, book) => {
    setDetailsBookModalOpen(false);
    setLendBookModalOpen(true);
    setBookToLend(book);
  };

  const handleLendBookModalClose = () => {
    setLendBookModalOpen(false);
  };

  const handleSetNewPageNumber = (number) => {
    const newFilters = {...filters, page:number}
      setFilters(newFilters)
  }

  return (
    <>
      {shareModalOpen && <ShareModal
        display={shareModalOpen}
        handleClose={handleShareModalClose}
        sharingLink={`https://app.labibli.com/collection/${collections[0]?.slug}`}
      />}
      {addBookModalOpen && (
      <AddBookModal
        display={addBookModalOpen}
        handleClose={handleAddBookModalClose}
        book={selectedBook}
        categories={categories}
      />
      )}
      {lendBookModalOpen && <LendBookModal
        display={lendBookModalOpen}
        handleClose={handleLendBookModalClose}
        book={bookToLend}
      />}
      {detailsBookModalOpen && (
      <DetailsBookModal
        display={detailsBookModalOpen}
        handleClose={handleCloseBookModal}
        handleEdit={handleBookEdit}
        handleLend={handleBookLend}
        book={selectedBook}
        categories={categories}
        isAdmin
        collection={{}}
      />
      ) }

      <Container component="main" className={classes.paper}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.content}
          >
            <Search
              onSearch={handleSearch}
              setOnlyAvailableFilter={handleAvailableFilter}
              handleOnFilterCategories={handleOnFilterCategories}
              categories={categories}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.content}
              >
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  onClick={handleShareModalOpen}
                  className={classes.button}
                >
                  {t('share_button')}
                </Button>

              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.content}
              >
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  onClick={handleAddBookModalOpen}
                  className={classes.button}
                >
                  {t('add_new_book')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Collection
            books={books}
            onBookClick={handleBookOpen}
            handleSetNewPageNumber={handleSetNewPageNumber}
            page={filters.page}
          />

        </Grid>
      </Container>
    </>
  );
}
