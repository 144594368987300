import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const getCustomers = () => axios.get(`${API_URL}/customers/`, { headers: authHeader() });
const createCustomer = (data) => axios.post(
  `${API_URL}/customers/`,
  data,
  { headers: authHeader() },
);

const customersService = {
  getCustomers,
  createCustomer,
};

export default customersService;
