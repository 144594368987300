import React, { useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import imageCompression from 'browser-image-compression';
import { Autocomplete } from '@material-ui/lab';
import { displayAlert } from '../../../../slices/feedbackSlice';
import DeleteBookModal from '../DeleteBookModal/DeleteBookModal';
import { createBook, updateBook } from '../../../../slices/itemsSlice';
import itemsService from '../../../../services/items.service';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  error: {
    color: 'red',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  imagePreview: {
    height: '100%',
    width: '100%',

  },
  deleteButton: {
    color: 'darkred',
  },
  actions: {
    justifyContent: 'space-between',
  },
  right: {
    justifySelf: 'right',
  },
  marginTop: {
    marginTop: '5px',
  },
}));

export default function AddBookModal({
  display, handleClose, book, categories,
}) {
  const classes = useStyles();
  const [imageFile, setImageFile] = useState();
  const [selectedCategories, setSelectedCategories] = useState({});
  const [imageLoading, setImageLoading] = useState(false);
  const [lookupLoading, setLookupLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookProperties, setBookProperties] = useState({});
  const [vignette, setVignette] = useState();
  const [deleteBookModalOpen, setDeleteBookModalOpen] = useState(false);

  const { t } = useTranslation(['myBooks', 'errors']);

  const dispatch = useDispatch();

  const setInitialBookProperties = useCallback((book) => {
    setBookProperties(
      {
        title: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          id: 'title',
          label: t('add_book.book_title'),
          name: 'title',
          value: book.title || '',
        },
        isbn: {
          variant: 'outlined',
          required: false,
          fullWidth: true,
          name: 'isbn',
          label: t('add_book.isbn'),
          id: 'isbn',
          value: book.isbn || '',
        },
        author: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'author',
          label: t('add_book.author'),
          id: 'author',
          value: book.author || '',
        },
        publisher: {
          variant: 'outlined',
          required: false,
          fullWidth: true,
          name: 'publisher',
          label: t('add_book.publisher'),
          id: 'publisher',
          value: book.publisher || '',
        },
        lang: {
          variant: 'outlined',
          required: false,
          fullWidth: true,
          name: 'lang',
          label: t('add_book.lang'),
          id: 'lang',
          value: book.lang || '',
        },
        featured: {
          variant: 'outlined',
          required: false,
          select: true,
          fullWidth: true,
          name: 'featured',
          label: t('add_book.featured'),
          id: 'featured',
          value: book.featured || false,
          children:
                        [<MenuItem key="true" value="true">
                          {t('add_book.true')}
                        </MenuItem>,
                          <MenuItem key="false" value="false">
                            {t('add_book.false')}
                          </MenuItem>]

          ,
        },
        archived: {
          variant: 'outlined',
          required: false,
          select: true,
          fullWidth: true,
          name: 'archived',
          label: t('add_book.archived'),
          id: 'archived',
          value: book.archived || false,
          children:
                        [<MenuItem key="true" value="true">
                          {t('add_book.true')}
                        </MenuItem>,
                          <MenuItem key="false" value="false">
                            {t('add_book.false')}
                          </MenuItem>]

          ,
        },
        published_year: {
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
          required: false,
          fullWidth: true,
          name: 'published_year',
          label: t('add_book.publishDate'),
          id: 'published_year',
          type: 'number',
          value: book.published_year || null,
        },
        inventory: {
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
          required: false,
          fullWidth: true,
          name: 'inventory',
          label: t('add_book.inventory'),
          id: 'inventory',
          type: 'number',
          value: book.inventory || 1,
        },
        location: {
          variant: 'outlined',
          required: false,
          fullWidth: true,
          name: 'location',
          label: t('add_book.location'),
          id: 'location',
          value: book.location || '',
        },
        description: {
          name: 'description',
          variant: 'outlined',
          required: false,
          fullWidth: true,
          multiline: true,
          rows: 4,
          id: 'description',
          label: t('add_book.description'),
          value: book.description || '',
        },
      },
    );
  }, [t]);

  useEffect(() => {
    setInitialBookProperties(book);
  }, [t, setInitialBookProperties, book]);

  useEffect(() => {
    setSelectedCategories(book.categories || []);
  }, [setSelectedCategories, book]);

  useEffect(() => {
    if (book.picture && display) {
      fetchPicture(book.picture)}
  }, [book, display]); //only to on display to avoid fetching images for hidden components

  useEffect(() => {
    if (imageFile) {
      setVignette(<img
        className={classes.imagePreview}
        alt={imageFile.name}
        src={URL.createObjectURL(imageFile)}
      />);
    } else {
      setVignette(null);
    }
  }, [imageFile, book, classes]);

  const fetchPicture = (url) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          setImageFile(blob);
        })
        .catch((err) => console.log(err));
  }

  const imageToBlob = (src) => {
      itemsService.downloadImage(src)
          .then(result => {
            setImageFile(result.data)
          })
          .catch(err => console.log('WTF', err))
  }


  const handleSuccess = () => {
    setImageFile(null);
    setError(null);
    dispatch(displayAlert(t('add_book.success'), 'success'));
    setLoading(false);
    setInitialBookProperties({});
    handleClose();
  };

  const handleError = (err) => {
    console.log('error', err);
    dispatch(displayAlert(err.message, 'error'));
    setLoading(false);
  };

  const verifyForm = () => {
    if (!bookProperties.author.value) {
      setError(t('error.author_mandatory'));
      return false;
    }
    if (!bookProperties.title.value) {
      setError(t('error.title_mandatory'));
      return false;
    }
    if (!error) {
      return true;
    }
    setError(t('error.image_format'));
    return false;
  };

  const handleSave = () => {
    const formIsValid = verifyForm();
    if (formIsValid) {
      const properties = Object.values(bookProperties).reduce((acc, elem) => {
        acc[elem.id] = elem.value;
        return acc;
      }, []);
      const bookWithImageAndCategories = {
        ...properties,
        picture: imageFile,
        categories: selectedCategories,
      };
      if (book.title) {
        dispatch(updateBook({ bookId: book.id, bookData: bookWithImageAndCategories }))
          .unwrap()
          .then(() => handleSuccess())
          .catch((err) => handleError(err));
      } else {
        dispatch(createBook(bookWithImageAndCategories))
          .unwrap()
          .then(() => handleSuccess())
          .catch((err) => handleError(err));
      }
    }
  };

  const handleDelete = () => {
    setDeleteBookModalOpen(true);
  };

  const handleOnChangeCategories = (event, newCategories) => {
    setSelectedCategories(newCategories);
  };

  const handleOnUploadImage = async (event) => {
    setImageLoading(true);
    setError(null);
    setImageFile(null);

    const file = event.target.files[0];

    if (file.type.includes('image')) {
      const compressedFile = await imageCompression(
        file,
        { maxWidthOrHeight: 700 },
      );
      setImageFile(compressedFile);
      setImageLoading(false);
    } else {
      setError(`${t('error.image_format')}: ${file.type}`);
      setImageLoading(false);
    }
  };

  const errorDisplay = () => {
    if (error) {
      return (
        <DialogContent dividers>
          <Typography gutterBottom className={classes.error}>
            {t(`errors:${error}`)}
          </Typography>
        </DialogContent>
      );
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    let { value, name } = target;
    if (name === 'isbn') {
      value = value.replace(/-/g, '');
    }
    if (name) {
      setBookProperties({
        ...bookProperties,
        [name]: {
          ...bookProperties[name],
          value,
        },
      });
    }
  };

  const handlePaste = async (event) => {
    setImageLoading(true);
    setError(null);

    const files = event.clipboardData.items;

    for (const file of files) {
      if (file.type.includes('image')) {
        const blob = file.getAsFile();

        const compressedFile = await imageCompression(
          blob,
          { maxWidthOrHeight: 700 },
        );
        setImageFile(compressedFile);
        setImageLoading(false);
      }
    }
    setImageLoading(false);
  };

  document.onpaste = function (event) {
    handlePaste(event);
  };

  const handleBookLookup = () => {
    const isbn = bookProperties.isbn.value;
    if (isbn) {
      setLookupLoading(true);
      itemsService.bookLookup(isbn)
        .then((response) => {
          setLookupLoading(false);
          const filledBook = {  ...response.data, ...book}
          setInitialBookProperties(filledBook)
          if (filledBook.picture) {
            imageToBlob(filledBook.picture)
          }
        }).catch((err) => {
          if (err.response?.status === 404){
            dispatch(displayAlert(t('search.no_book_found'), 'error'));
          }
          else {
            dispatch(displayAlert(t('search.no_book_found'), 'error'));
          }
          setLookupLoading(false);
        });
    }
  };

  const handleDeleteBookModalClose = () => {
    setDeleteBookModalOpen(false);
    handleClose()
  };

  return (
    <>
      {deleteBookModalOpen &&
      <DeleteBookModal
          display={deleteBookModalOpen}
          handleClose={handleDeleteBookModalClose}
          book={book}
      />}
      <Dialog
        open={display}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle className={classes.root} disableTypography>
          <Typography type="h4" variant="h4">
            {t('add_book.title')}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        <DialogContent dividers>

          <form
            className={classes.form}
            noValidate
            onSubmit={handleSave}
            onChange={handleChange}
            onClick={handleChange}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} key="isbn">
                <TextField
                  {...bookProperties.isbn}
                  InputProps={{
                    endAdornment: (
                      <div className={classes.wrapper}>
                        <InputAdornment position="end">
                          {lookupLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                          <IconButton
                            aria-label="book lookup"
                            onClick={handleBookLookup}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      </div>
                    ),
                  }}
                />
              </Grid>
              {Object.values(bookProperties).map((property) => (
                property.id !== 'isbn'
                                && (
                                <Grid item xs={12} key={property.id}>
                                  <TextField {...property} />
                                </Grid>
                                )
              ))}
            </Grid>
          </form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                className={classes.marginTop}
                multiple
                id="tags-outlined"
                defaultValue={book.categories}
                options={categories}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={handleOnChangeCategories}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('add_book.category_placehoder')}
                    label={t('add_book.categories')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button variant="contained" component="label" color="secondary">
                {
                                    (book.picture || imageFile)
                                      ? t('add_book.change_picture_button')
                                      : t('add_book.add_picture_button')

                                }
                <input
                  id="image-input"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleOnUploadImage}
                />
              </Button>

            </Grid>
            <Grid item xs={12}>
              <Typography type="p" variant="body1">
                {t('add_book.paste_instruction')}
              </Typography>

            </Grid>
            <Grid item xs={12}>
              {vignette}
            </Grid>
          </Grid>
        </DialogContent>
        {errorDisplay()}
        <DialogActions className={book.title ? classes.actions : classes.right}>

          {book.title
                    && (
                    <Button
                      className={classes.deleteButton}
                      type="submit"
                      disabled={imageLoading || loading}
                      variant="text"
                      onClick={handleDelete}
                    >
                      {t('add_book.delete_button')}
                    </Button>
                    )}
          <div className={classes.wrapper}>
            <Button
              type="submit"
              disabled={imageLoading || loading}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              {t('add_book.save_button')}
            </Button>
            {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>

        </DialogActions>

      </Dialog>
    </>
  );
}
