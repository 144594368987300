import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const createCheckoutLink = (data) => axios.post(`${API_URL}/payment/create/`, data, { headers: authHeader() });

const organizationService = {
  createCheckoutLink,
};

export default organizationService;
