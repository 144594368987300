import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ItemsService from '../services/items.service';
import { displayAlert } from './feedbackSlice';
import { manageError, removeObjectWithId, replaceObjectWithId } from '../utils/helpers';

// Categories
export const getCategories = createAsyncThunk(
  'items/categories/list',
  async (thunkAPI) => {
    try {
      const data = await ItemsService.getCategories();
      return { categories: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createCategory = createAsyncThunk(
  'items/categories/post',
  async (categoryData, thunkAPI) => {
    try {
      const data = await ItemsService.createCategory(categoryData);
      return { category: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateCategory = createAsyncThunk(
  'items/categories/put',
  async (categoryData, thunkAPI) => {
    try {
      const data = await ItemsService.updateCategory(categoryData);
      return { category: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteCategory = createAsyncThunk(
  'items/categories/delete',
  async (category, thunkAPI) => {
    try {
      await ItemsService.deleteCategory(category.id);
      return { category };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

// Lendings
export const getLendings = createAsyncThunk(
  'items/lendings/list',
  async (thunkAPI) => {
    try {
      const data = await ItemsService.getLendings();
      return { lendings: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createLending = createAsyncThunk(
  'items/lendings/post',
  async (lendingData, thunkAPI) => {
    try {
      const data = await ItemsService.createLending(lendingData);
      return { lending: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const returnLending = createAsyncThunk(
  'items/lendings/return',
  async (lendingId, thunkAPI) => {
    try {
      const data = await ItemsService.returnLending(lendingId);
      return { lending: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

// Books
export const getBooks = createAsyncThunk(
  'items/books/list',
  async (
    filters,
    thunkAPI) => {
    try {
      const data = await ItemsService.getBooks(filters);
      return { books: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createBook = createAsyncThunk(
  'items/books/post',
  async (bookData, thunkAPI) => {
    try {
      const data = await ItemsService.createBook(bookData);
      return { book: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteBook = createAsyncThunk(
  'items/books/delete',
  async (book, thunkAPI) => {
    try {
      await ItemsService.deleteBook(book.id);
      return { book };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateBook = createAsyncThunk(
  'items/books/update',
  async ({ bookData, bookId }, thunkAPI) => {
    try {
      const data = await ItemsService.updateBook(bookId, bookData);
      return { book: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

// Collections
export const getSharedCollection = createAsyncThunk(
  'items/collection/shared',
  async ({ slug, filters }, thunkAPI) => {
    try {
      const data = await ItemsService.getSharedCollection(slug, filters);
      return { sharedCollection: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getSharedCategories = createAsyncThunk(
  'items/categories/shared',
  async ({ slug }, thunkAPI) => {
    try {
      const data = await ItemsService.getSharedCategories(slug);
      return { categories: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCollections = createAsyncThunk(
  'items/collections/list',
  async (thunkAPI) => {
    try {
      const data = await ItemsService.getCollections();
      return { collections: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, 'error'));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const itemsSlice = createSlice({
  name: 'items',
  initialState: {
    categories: [],
    lendings: [],
    books: {
      count: 0,
      num_pages: 0,
      results: [],
    },
    sharedCollection: {
      books: {
        count: 0,
        num_pages: 0,
        results: [],
      },
    },
    collections: [],
  },
  reducers: {},
  extraReducers: {

    // Categories
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.categories;
    },
    [getCategories.rejected]: (state, action) => {
      state.categories = [];
    },
    [createCategory.fulfilled]: (state, action) => {
      state.categories = [...state.categories, action.payload.category];
    },
    [updateCategory.fulfilled]: (state, action) => {
      const updatedCategory = action.payload.category;
      const oldCategories = [...state.categories];
      state.categories = replaceObjectWithId(oldCategories, updatedCategory);
    },
    [deleteCategory.fulfilled]: (state, action) => {
      const oldCategory = action.payload.category;
      const oldCategories = [...state.categories];
      state.categories = removeObjectWithId(oldCategories, oldCategory);
    },

    // Lendings
    [getLendings.fulfilled]: (state, action) => {
      state.lendings = action.payload.lendings;
    },
    [getLendings.rejected]: (state, action) => {
      state.lendings = [];
    },
    [createLending.fulfilled]: (state, action) => {
      state.lendings = [...state.lendings, action.payload.lending];
    },
    [returnLending.fulfilled]: (state, action) => {
      const updatedLending = action.payload.lending;
      const oldLendings = [...state.lendings];
      state.lendings = removeObjectWithId(oldLendings, updatedLending);
    },
    [returnLending.rejected]: (state, action) => {
      state.lendings = [...state.lendings];
    },

    // Books
    [getBooks.fulfilled]: (state, action) => {
      state.books = action.payload.books;
    },
    [createBook.fulfilled]: (state, action) => {
      state.books.results = [...state.books.results, action.payload.book];
    },
    [updateBook.fulfilled]: (state, action) => {
      const updatedBook = action.payload.book;
      const oldBooks = [...state.books.results];
      state.books.results = replaceObjectWithId(oldBooks, updatedBook);
    },
    [returnLending.rejected]: (state, action) => {
      state.books = [...state.books];
    },
    [deleteBook.fulfilled]: (state, action) => {
      const oldBook = action.payload.book;
      const oldBooks = [...state.books.results];
      state.books.results = removeObjectWithId(oldBooks, oldBook);
    },

    // Shared Collection
    [getSharedCollection.fulfilled]: (state, action) => {
      state.sharedCollection = action.payload.sharedCollection;
    },
    [getSharedCollection.rejected]: (state, action) => {
      state.sharedCollection = {};
    },
    [getSharedCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.categories;
    },
    [getSharedCategories.rejected]: (state, action) => {
      state.categories = [];
    },

    // Collections
    [getCollections.fulfilled]: (state, action) => {
      state.collections = action.payload.collections;
    },
    [getCollections.rejected]: (state, action) => {
      state.collections = [];
    },
  },
});

export default itemsSlice.reducer;
