import React, { useEffect, useState, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  error: {
    color: 'red',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  imagePreview: {
    height: '100%',
    width: '100%',
  },
}));

export default function DetailsBookModal({
  display, handleClose, book, handleEdit, isAdmin, handleLend,collection
}) {
  const classes = useStyles();
  const [bookProperties, setBookProperties] = useState({});
  const [vignette, setVignette] = useState();

  const { t } = useTranslation('myBooks');

  const displayCategories = (categories) => {
    const categoriesNames = categories.map((category) => category.name);
    return categoriesNames.join(', ');
  };

  const bookCategories = displayCategories(book.categories || []);

  const setInitialBookProperties = useCallback((book) => {
    setBookProperties(
      {
        title: {
          variant: 'outlined',
          margin: 'normal',
          required: true,
          fullWidth: true,
          id: 'title',
          label: t('add_book.book_title'),
          name: 'title',
          value: book.title || '',
        },
        isbn: {
          variant: 'outlined',
          required: false,
          fullWidth: true,
          name: 'isbn',
          label: t('add_book.isbn'),
          id: 'isbn',
          value: book.isbn || '',
        },
        author: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'author',
          label: t('add_book.author'),
          id: 'author',
          value: book.author || '',
        },
        publisher: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'publisher',
          label: t('add_book.publisher'),
          id: 'publisher',
          value: book.publisher || '',
        },
        lang: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'lang',
          label: t('add_book.lang'),
          id: 'lang',
          value: book.lang || '',
        },
        status: {
          variant: 'outlined',
          required: true,
          fullWidth: true,
          name: 'status',
          label: t('add_book.status'),
          id: 'status',
          value: t(`add_book.${book.status || 'available'}`),
        },
        published_year: {
          type: 'date',
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
          required: true,
          fullWidth: true,
          name: 'published_year',
          label: t('add_book.publishDate'),
          id: 'published_year',
          value: book.published_year || '',
        },
        inventory: {
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
          required: false,
          fullWidth: true,
          name: 'inventory',
          label: t('add_book.inventory'),
          id: 'inventory',
          type: 'number',
          value: (book.inventory === undefined || book.inventory === null) ? 1 : book.inventory,
        },
        location: {
          variant: 'outlined',
          required: false,
          fullWidth: true,
          name: 'location',
          label: t('add_book.location'),
          id: 'location',
          value: book.location || '',
        },
        categories: {
          name: 'categories',
          variant: 'outlined',
          required: true,
          fullWidth: true,
          multiline: true,
          rows: 4,
          id: 'categories',
          label: t('add_book.categories'),
          value: bookCategories,
        },
        description: {
          name: 'description',
          variant: 'outlined',
          required: true,
          fullWidth: true,
          multiline: true,
          rows: 4,
          id: 'description',
          label: t('add_book.description'),
          value: book.description || '',
        },

      },
    );
  }, [t, bookCategories]);

  useEffect(() => {
    setInitialBookProperties(book);
  }, [t, setInitialBookProperties, book]);

  useEffect(() => {
    setVignette(
      <img
        className={classes.imagePreview}
        alt={`${t('cover_alt')} ${book.title}`}
        src={book.picture}
      />,
    );
  }, [book, classes, t]);

  const handleBorrow = () => {
    alert(`${t('details_book.borrow_message')} ${collection?.organization_email}`);
  };

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h4" variant="h4">
          {book.title}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {isAdmin
              && (
              <>
                {book.status === 'borrowed'
                  ? (
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        fullWidth
                        disabled
                      >
                        {t('details_book.borrowed')}
                      </Button>
                    </Grid>
                  )

                  : (
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        fullWidth
                        onClick={(event) => handleLend(event, book)}
                      >
                        {t('details_book.lend')}
                      </Button>
                    </Grid>
                  )}
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    component="label"
                    color="secondary"
                    fullWidth
                    onClick={(event) => handleEdit(event, book)}
                  >
                    {t('details_book.edit')}
                  </Button>
                </Grid>
              </>
              )}
          <Grid item xs={12}>
            {vignette}
          </Grid>

          {Object.values(bookProperties).map((property) => (
              (property.value !== undefined && property.value !== null)
            ? (
              <React.Fragment key={property.id}>
                <Grid item xs={12} md={6}>
                  <Typography type="h4" variant="h5">
                    {property.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography type="h4" variant="body1">
                    {property.value}
                  </Typography>
                </Grid>
              </React.Fragment>
            )
            : null))}

          {!isAdmin && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              fullWidth
              onClick={(event) => handleBorrow(event, book)}
            >
              {t('details_book.borrow')}
            </Button>
          </Grid>
          )}
        </Grid>

      </DialogContent>

    </Dialog>
  );
}
