import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import AddCustomerModal from './AddCustomerModal/AddCustomerModal';

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '99%',
    padding: '20px',
  },
}));

export default function Customers() {
  const classes = useStyles();
  const { t } = useTranslation('customers');
  const customers = useSelector((state) => state.customers.customers)
    .map((customer) => ({ ...customer }));

  const [addCustomerModalOpen, setCustomerModalOpen] = useState(false);

  const handleAddCustomerModalClose = () => {
    setCustomerModalOpen(false);
  };

  const handleAddCustomerModalOpen = () => {
    setCustomerModalOpen(true);
  };

  return (
    <>
      <AddCustomerModal
        display={addCustomerModalOpen}
        handleClose={handleAddCustomerModalClose}
      />
      {/* <ViewCustomerModal
      display={viewCustomerModalOpen}
      handleClose={handleViewCustomerModalClose} /> */}
      {/* <EditCustomerModal
      display={editCustomerModalOpen}
      handleClose={handleEditCustomerModalClose} /> */}
      {/* <DeleteCustomerModal
       display={deleteCustomerModalOpen}
       handleClose={handleDeleteCustomerModalClose} /> */}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h2">
            {t('customers')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            onClick={handleAddCustomerModalOpen}
          >
            {t('add_customer.button')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              { title: t('firstName'), field: 'first_name' },
              { title: t('lastName'), field: 'last_name' },
              { title: t('email'), field: 'email' },
              { title: t('phoneNumber'), field: 'phone_number' },
              { title: t('language'), field: 'language' },
              { title: t('note'), field: 'note' },
            ]}
            data={customers}
            title={t('customers')}
            localization={{
              toolbar: {
                searchTooltip: t('table.search'),
                searchPlaceholder: t('table.search'),
              },
              pagination: {
                labelDisplayedRows: t('table.row_count'),
                labelRowsSelect: t('table.rows'),
              },
            }}
            options={{
              emptyRowsWhenPaging: false,
              pageSize: 20,
            }}
          />

        </Grid>

      </Grid>
    </>
  );
}
