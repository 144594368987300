import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';

import { displayAlert } from '../../../../slices/feedbackSlice';
import { returnLending } from '../../../../slices/itemsSlice';
import { displayDate } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  validateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ReturnBookModal({
  display, handleClose, lending,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['myBooks', 'customers']);
  const [currentLending] = useState(lending );

  const handleReturn = () => {
    dispatch(returnLending(currentLending.id))
      .unwrap()
      .then(
        () => {
          dispatch(displayAlert(t('return_modal.success'), 'success'));
          handleClose();
        },
      ).catch(() => dispatch(displayAlert(t('return_modal.error'), 'error')));
  };

  const modalContent = currentLending && (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t('return_modal.title')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" />
          </Grid>
          <Grid item xs={12}>
            <Typography type="p" variant="body1">
              {`${t('return_modal.return_book')} "${currentLending.book.title}", 
                ${t('return_modal.on_date')} ${displayDate(currentLending.lent_at)} 
                ${t('return_modal.borrowed_by')} ${currentLending.customer.first_name} ${currentLending.customer.last_name}`}

            </Typography>
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container alignItems="flex-end" justifyContent="flex-end" direction="row">
          <Grid item xs={6} className={classes.validateButton}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              onClick={handleReturn}
            >
              {t('return_modal.return_button')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
