import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import CustomPagination from './Pagination/Pagination';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  image: {
    height: '450px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  cardContent: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
    height: '100%',
    alignContent: 'bottom',
  },
  card: {
    height: '100%',
    width: '281.25px',
    position: 'relative',
  },
  bookDetails: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '281.25px',
  },
  featuredContainer: {
    borderStyle: 'solid',
    border: theme.palette.primary.main,
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    padding: `${theme.spacing(2)}px 0`,
    flexWrap: 'wrap',
    rowGap: `${theme.spacing(2)}px`,
  },
  archived: {
    opacity: '50%',
    backgroundColor: 'lightgrey',
  },

}));

export default function Collection({
  books, onBookClick, handleSetNewPageNumber, page,
}) {
  const classes = useStyles();
  const { t } = useTranslation('myBooks');

  const featuredBooks = books.results.filter((book) => book.featured === true && book.deleted !== true);
  const otherBooks = books.results.filter((book) => book.featured !== true && book.deleted !== true);

  // const DEFAULT_PICTURE_PLACEHOLDER = 'https://sfo3.digitaloceanspaces.com/labibli-s3/pictures/4916.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BGLZPAXQMT7H2HGFREQI%2F20211107%2Fsfo3%2Fs3%2Faws4_request&X-Amz-Date=20211107T214553Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=630b9a090fbd47165367a45bc2d19ca50b27af1349abb1995af89980da5863c4';
  const DEFAULT_PICTURE_PLACEHOLDER = 'https://sfo3.digitaloceanspaces.com/labibli-s3/pictures/blob.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BGLZPAXQMT7H2HGFREQI%2F20211111%2Fsfo3%2Fs3%2Faws4_request&X-Amz-Date=20211111T181641Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=e86cd5f0bbff30cc1556860ecc9c1ed26eda1b4dc72e3633d66845eff24c974d';
  return (
    <>
      {!!featuredBooks.length
            && (
            <div className={classes.featuredContainer}>
              <Grid item xs={12}>
                <Typography variant="h1" component="h1" align="center">
                  {t('add_book.featured')}
                </Typography>
              </Grid>
              {
                        featuredBooks.map((book) => {
                          const cardClasses = `${classes.card} ${book.archived ? classes.archived : 'foo'}`;

                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              className={classes.content}
                              key={book.id}
                            >
                              <Card className={cardClasses}>
                                <CardActionArea
                                  className={classes.card}
                                  onClick={(event) => onBookClick(event, book)}
                                >
                                  <CardHeader
                                    title={book.title}
                                    titleTypographyProps={{ variant: 'h3' }}
                                  />
                                  <CardContent className={classes.cardContent}>
                                    <Grid container spacing={2} className={classes.bookDetails}>
                                      <Grid item xs={12}>
                                        <Typography variant="body1" component="p">
                                          {book.author}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <CardMedia
                                      className={classes.image}
                                      image={book.picture || DEFAULT_PICTURE_PLACEHOLDER}
                                      title={book.name}
                                    />

                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>

                          );
                        })
                    }
            </div>
            )}
      <Grid item xs={12}>
        {!!otherBooks.length && (
        <Typography variant="h1" component="h1" align="center">
          {t('collection')}
        </Typography>
        )}
      </Grid>
      {otherBooks.map((book) => {
        const cardClasses = `${classes.card} ${book.archived ? classes.archived : 'foo'}`;

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.content}
            key={book.id}
          >
            <Card className={cardClasses}>
              <CardActionArea
                className={classes.card}
                onClick={(event) => onBookClick(event, book)}
              >
                <CardHeader
                  title={book.title}
                  titleTypographyProps={{ variant: 'h3' }}
                />
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={2} className={classes.bookDetails}>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="p">
                        {book.author}
                      </Typography>
                    </Grid>
                  </Grid>
                  <CardMedia
                    className={classes.image}
                    image={book.picture || DEFAULT_PICTURE_PLACEHOLDER}
                    title={book.name}
                  />

                </CardContent>
              </CardActionArea>
            </Card>

          </Grid>
        );
      })}
      {!!books.results.length
      && (
      <Grid item xs={12}>
        <CustomPagination count={books.num_pages} handleSetNewPageNumber={handleSetNewPageNumber} page={page} />
      </Grid>
      )}
    </>
  );
}
