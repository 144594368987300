import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import { NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import { displayAlert } from '../../../../slices/feedbackSlice';
import Search from '../../../Search/Search';
import AddCustomerModal from '../../Customers/AddCustomerModal/AddCustomerModal';
import { createLending } from '../../../../slices/itemsSlice';

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  sharingButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  copyButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function LendBookModal({ display, handleClose, book }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['myBooks', 'customers']);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [addCustomerModalOpen, setCustomerModalOpen] = useState(false);

  const isSubscribed = useSelector((state) => state.organization.organization.is_subscribed);
  const customers = useSelector((state) => state.customers.customers)
    .map((customer) => ({ ...customer }));

  const handleAddCustomerModalClose = () => {
    setCustomerModalOpen(false);
  };

  const handleAddCustomerModalOpen = () => {
    setCustomerModalOpen(true);
  };

  const handleLend = () => {
    const lendingData = {
      customer: selectedCustomer.id,
      book: book.id,
    };
    dispatch(createLending(lendingData))
      .unwrap()
      .then(
        () => {
          dispatch(displayAlert(t('lend_modal.success'), 'success'));
          handleClose();
        },
      )
      .catch(() => {
        dispatch(displayAlert(t('lend_modal.error'), 'error'));
      });
  };

  const handleSelectCustomer = (rows) => {
    const row = rows[0];
    setSelectedCustomer(row);
  };

  const filterCustomers = (query) => {
    const filtered = customers.filter((customer) => (
      ((customer.first_name || '').toLowerCase().includes(query.toLowerCase())
      || (customer.last_name || '').toLowerCase().includes(query.toLowerCase())
      || (customer.email || '').toString().includes(query.toLowerCase())
      || (customer.phone || '').toLowerCase().includes(query.toLowerCase()))));
    setFilteredCustomers(filtered.slice(0, 5));
  };

  const modalContent = (
    isSubscribed
      ? (
        // Subscribed
        <>
          <AddCustomerModal
            display={addCustomerModalOpen}
            handleClose={handleAddCustomerModalClose}
          />
          <DialogTitle className={classes.root} disableTypography>
            <Typography type="h3" variant="h3">
              {t('lend_modal.title')}
            </Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography type="p" variant="body1">
                  {t('lend_modal.find_customer')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Search onSearch={filterCustomers} />
              </Grid>
              <Grid item xs={12}>
                {!!filteredCustomers.length && (
                <MaterialTable
                  columns={[
                    { title: t('customers:firstName'), field: 'first_name' },
                    { title: t('customers:lastName'), field: 'last_name' },
                    { title: t('customers:email'), field: 'email' },
                    { title: t('customers:phoneNumber'), field: 'phone' },
                  ]}
                  data={filteredCustomers}
                  title={t('customers')}
                  options={{
                    search: false,
                    toolbar: false,
                    showEmptyDataSourceMessage: false,
                    paging: false,
                    selection: true,
                    header: false,
                  }}
                  onSelectionChange={(rows) => handleSelectCustomer(rows)}
                />
                )}
              </Grid>

            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid container alignItems="flex-end" justify="flex-end" direction="row">
              <Grid item xs={6} className={classes.sharingButton}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={handleAddCustomerModalOpen}
                >
                  {t('lend_modal.add_customer_button')}
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.copyButton}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={handleLend}
                >
                  {t('lend_modal.lend_button')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )
      : (

        // Not subscribed
        <>
          <DialogTitle className={classes.root} disableTypography>
            <Typography type="h3" variant="h3">
              {t('share_modal.title')}
            </Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <DialogContent dividers>
            <Typography type="p" variant="body1">
              {t('lend_modal.no_subscription')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Grid container alignItems="flex-end" justify="flex-end" direction="row">
              <Grid item xs={6} className={classes.copyButton}>
                <Button
                  variant="contained"
                  color="primary"
                  component={NavLink}
                  to="myaccount"
                >
                  {t('lend_modal.subscribe')}
                </Button>
              </Grid>
            </Grid>

          </DialogActions>
        </>
      )
  );

  return (
    <Dialog
      open={display}
      onClose={handleClose}
      fullWidth
    >
      {modalContent}
    </Dialog>
  );
}
