import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Plan from './Plan/Plan';
import plans from '../../../../../assets/plans/plans.json';

export default function Plans({ selectedPlanId, handleSelectPlan }) {
  const [t] = useTranslation('myAccount');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          {t('plans.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          {t('plans.instructions')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {Object.values(plans.plans).map((plan) => (
            <Plan
              key={plan.id}
              selected={selectedPlanId === plan.id}
              plan={plan}
              onClick={handleSelectPlan}
            />
          ))}

        </Grid>
      </Grid>
    </Grid>
  );
}
