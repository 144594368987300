import React from "react";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

export default function SocialMediaButtons({url, quote, hashtags}) {

    return (

        <>
            <FacebookShareButton
                url={url}
                quote={quote}
                hashtag={"#" + hashtags?.[0]}>
                <FacebookIcon color={"primary"}
                              fontSize={"large"}/>
            </FacebookShareButton>
            <TwitterShareButton
                url={url}
                title={quote + "\n\n ➡ "}
                hashtags={hashtags}>
                <TwitterIcon color={"primary"} fontSize={"large"}/>
            </TwitterShareButton>
            <WhatsappShareButton
                url={url}
                title={quote}
                separator={"\n\n ➡ "}>
                <WhatsAppIcon color={"primary"} fontSize={"large"}/>
            </WhatsappShareButton>
        </>
    )
        ;
}
