import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ReturnBookModal from '../Books/ReturnBookModal/ReturnBookModal';
import LendingDetailsModal from './LendingDetailsModal/LendingDetailsModal';
import theme from '../../../theme';
import { getLendings } from '../../../slices/itemsSlice';

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '99%',
    padding: '20px',
  },
}));

export default function Lendings() {
  const classes = useStyles();
  const { t } = useTranslation('lendings');

  const dispatch = useDispatch();

  const lendings = useSelector((state) => state.items.lendings)
    .map((lending) => ({ ...lending }));

  const [returnBookModalOpen, setReturnBookModalOpen] = useState(false);
  const [lendingDetailsModalOpen, setLendingDetailsModalOpen] = useState(false);
  const [lending, setLending] = useState();

  useEffect(() => {
    dispatch(getLendings());
  }, [dispatch]);

  const handleReturnBookModalClose = () => {
    setReturnBookModalOpen(false);
    setLending(null);
  };

  const handleReturnBookModalOpen = () => {
    setReturnBookModalOpen(true);
  };

  const handleLendingDetailsModalOpen = () => {
    setLendingDetailsModalOpen(true);
  };

  const handleLendingDetailsModalClose = () => {
    setLendingDetailsModalOpen(false);
  };

  const handleClick = (event, row) => {
    setLending(row);
    handleLendingDetailsModalOpen(row);
  };

  return (
    <>
      {lending && (
      <>
        <ReturnBookModal
          display={returnBookModalOpen}
          handleClose={handleReturnBookModalClose}
          lending={lending}
        />
        <LendingDetailsModal
          display={lendingDetailsModalOpen}
          handleClose={handleLendingDetailsModalClose}
          handleReturnModalOpen={handleReturnBookModalOpen}
          lending={lending}
        />
      </>
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h2">
            {t('lendings')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <MaterialTable
            columns={[
              { title: t('title'), field: 'book.title' },
              { title: t('lent_on'), field: 'lent_at', type: 'date' },
              { title: t('due_on'), field: 'due_at', type: 'date' },
              { title: t('firstName'), field: 'customer.first_name' },
              { title: t('lastName'), field: 'customer.last_name' },
            ]}
            data={lendings}
            title={t('ongoing_lendings')}
            localization={{
              toolbar: {
                searchTooltip: t('table.search'),
                searchPlaceholder: t('table.search'),
              },
              pagination: {
                labelDisplayedRows: t('table.row_count'),
                labelRowsSelect: t('table.rows'),
              },
            }}
            onRowClick={
              (event, row) => handleClick(event, row)
            }
            options={{
              rowStyle: (rowData) => ({
                backgroundColor: ((rowData.is_past_due)) ? theme.palette.error.light : '#FFF',
              }),
              emptyRowsWhenPaging: false,
              pageSize: 20,
            }}
          />

        </Grid>
      </Grid>
    </>
  );
}
